.confirm-btn {
    $self: &;
    display: flex;
    height: 40px;
    width: fit-content;
    border-radius: 4px;
    padding: 0 24px;
    align-items: center;
    justify-content: center;
    background-color: #0E65E5;
    color: #fff;
    opacity: 1;
    cursor: pointer;
    border: 1px solid #0E65E5;
    appearance: none;
    font-size: 100%;
    transition: 0.3s;

    &:hover {
        background-color: #3E83EA;
        border-color: #3E83EA;

        &.inverted {
            background-color: #0E65e5;
            border-color: #0E65e5;
            color: #fff;
        }

        &.disable {
            background-color: #0E65e5;
        }
    }

    &.inverted {
        background-color: transparent;
        color: #3E83EA;
    }

    &.disable {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.slide-toggle {
    display: flex;
    align-items: center;

    &__label {
        margin-left: 5px;
        font-size: 14px;
    }
}


.react-toggle {
  $self: &;
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  &-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  &-track {
    width: 30px;
    height: 14px;
    padding: 0;
    border-radius: 30px;
    background-color: #fff;
    border: 1px solid #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &:hover:not(#{$self}--disabled) {
      #{$self}-track {
        background-color: #fff;
      }
  }

  &--checked {
      #{$self}-track {
        background-color: #0373fc;
        border: 1px solid #0373fc;
      }

      &:hover:not(#{$self}--disabled) {
          #{$self}-track {
            background-color: #0373fc;
          }
      }

      #{$self}-track-check {
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
      }
       
      #{$self}-track-x {
          opacity: 0;
      }

      #{$self}-thumb {
        left: 16px;
        border-color: #0373fc;
        background-color: #fff;
      }
  }

  &-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  &-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  &-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #4a5056;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;

    #{$self}:active:not(#{$self}--disabled) {
        -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
        -moz-box-shadow: 0px 0px 5px 5px #0099E0;
        box-shadow: 0px 0px 5px 5px #0099E0;
    }
  }

}