$alternating-row-grey: #f8f9fa;

.api-bundle-list {
    $self: &;
    &__title {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        font-size: 14px;
    }

    &__info {
        opacity: 0.6;
        display: flex;
        align-items: center;
        margin-top: 5px;

        &-text {
            margin-left: 3px;
            font-size: 14px;
        }
    }

    &__row {
        display: flex;
        align-items: center;
        padding: 8px 5px;
        background-color: #fff;

        &--even {
            @extend #{$self}__row;
            background-color: $alternating-row-grey;
        }

        &-label {
            font-size: 14px;
            margin-left: 10px;
        }
    }
}