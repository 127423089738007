table {
  border-spacing: 0;
  border-radius: 3px;
  width: 100%;
}

tr {
  text-align: left;
  cursor: pointer;
}

th {
  padding-left: 20px;
  border-bottom: 1px solid rgb(237, 238, 240);
  height: 50px;
}

td {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

td.apiKey {
  padding: 0;
}

tbody > tr:nth-child(odd) {
  background: rgba(248, 249, 250, 1);
}

tbody > tr:nth-child(even) {
  background: #ffffff;
}

tbody > tr:hover {
  background-color: rgb(232, 239, 251);
}

tbody > tr {
  height: 40px;
}