$text-blue: #0E65E5; 
$selector-background-grey: #DEE2E6;
$menu-width: 384px;

.typeahead-input {
    box-sizing: border-box;
    width: $menu-width !important;
    height: 48px;
    display: flex;
    align-items: center; 
    padding: 0 18px;
    background-color: $selector-background-grey;
    border-radius: 4px 0px 0px 4px;
    border: none;
    font-size: 16px;
    font-family: 'Lato';
}

.company-menu {
    background-color: #fff;
    border: 2px solid $selector-background-grey;
    box-sizing: border-box;
    width: $menu-width;
    position: relative;
    top: 5px;
    border-radius: 4px;
    z-index: 3;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);

    &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.1s, color 0.1s;

        &:hover {
            background-color: $text-blue;
            color: #fff;
        }

        &:last-of-type {
            border-radius: 0px 0px 4px 4px;
        }

        &:first-of-type {
            border-radius: 4px 4px 0px 0px;
        }

    }
}

.rbt-menu.dropdown-menu.show {
    background-color: #fff;
    border: 2px solid $selector-background-grey;
    box-sizing: border-box;
    width: $menu-width !important;
    position: relative;
    top: 5px !important;
    border-radius: 4px;
    z-index: 3;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
}

.dropdown-item {
    padding: 10px 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.1s, color 0.1s;
    text-decoration: none;
    &:hover {
        background-color: $text-blue;
        color: #fff;
    }
    &:last-of-type {
        border-radius: 0px 0px 4px 4px;
    }

    &:first-of-type {
        border-radius: 4px 4px 0px 0px;
    }
}

.rbt-input-hint {
    box-sizing: border-box;
    width: 340px !important;
    display: flex;
    align-items: center; 
    padding: 0 18px;
    font-size: 16px;
    font-family: 'Lato';
    &[placeholder] {
        text-overflow: ellipsis;
    }
}

.company-selector {
    height: 48px;

    &__clear-btn {
        height: 48px;
        background-color: $selector-background-grey;
        display: flex;
        align-items: center;
        padding-right: 14px;
        border-radius: 0px 4px 4px 0px;
        cursor: pointer;
    }

    .disabled {
        cursor: auto;
    }
}



.typeahead-input {
    outline: none !important;
}

.sr-only {
    visibility: hidden;
}