$toolbar-grey: #707883;
$selected-blue: #CEE0F9;
$blue: #0E65E5;
$coral: #F56E6E;

.app-nav {
    font-size: 14px;
    padding: 5px 12px 0px 12px;
    position: relative;
    z-index: 10;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);

    &__top {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        border-bottom: 1px solid #D2D7DE;
    }

    &__bottom {
        display: flex;
        padding: 11px 0px;
        align-items: center;
    }

    &__logo {
        flex-grow: 1;
        > img {
            width: 132px;
        }
    }
}

.nav-button {
    $self: &;
    text-decoration: none;
    height: 100%;

    &__label {
        display: flex;
        height: 32px;
        align-items: center;
        padding: 0px 16px;
        border-radius: 4px;
        color: $toolbar-grey;
        background-color: #fff;
        margin-right: 8;
        font-weight: 600;
        
        &--selected {
            @extend #{$self}__label;
            background-color: $selected-blue;
            color: $blue
        }
    }

}

.user-control {
    display: flex;
    align-items: center;
    margin-left: 23px;

    &__logout {
        padding: 8px;
        cursor: pointer;
    }

    &__name {
        display: flex;
        border: 1px solid $coral;
        background-color: $coral; 
        color: #fff;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        margin-left: 24px;
        cursor: pointer;
    }

    &__name-container {
        font-weight: 600;
        font-size: 12px;
    }
}