$background-blue: rgb(237, 242, 251);
$text-grey-dark: #495057;
$padding-y: 21px;
$padding-x: 24px;
$top-toolbar-height: calc(32px + #{$padding-y});

.api-key-management-page {
    padding: $padding-y $padding-x;
    background-color: $background-blue;
    flex-grow: 1;

    &__bundle-section {
        margin-bottom: 10px;

        &-title {
            display: flex;
            margin-bottom: 15px;
        }

        &-bundle-container {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__application-list {
        &-title {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }

        &-table {
            position: relative;
        }

        &-loader {
            position: absolute;
            z-index: 100;
            left: 50%;
            top: 50%;
            margin-top: -20px;
        }
    }

    &__section-title {
        display: flex;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
        align-items: center;
        flex-grow: 1
    }

    &__bundles-placeholder {
        height: 266px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.no-bundles {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $background-blue;
    position: relative;

    &__top-toolbar {
        position: relative;
        z-index: 4;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: $padding-x; 
        height: $top-toolbar-height; 
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        margin-top: -53px; 
    }

    &__top-text {
        margin-top: 34px;
        font-size: 24px;
        font-weight: 200;
        color: black;
    }

    &__bottom-text {
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        color: $text-grey-dark;
        margin-bottom: 50px;
    }

    &__image-container {
        width: 225px;
        height: 160px;
    }

    &__image {
        object-fit: contain;
        width: 100%;
    }

}

.no-active-keys {
    &__image {
        object-fit: contain;
        width: 100%;

        &-container {
            width: 150px;
        }
    }

    &__top-text {
        margin-top: 16px;
        font-size: 24px;;
        font-weight: 200;
        color: black;
    }

}


