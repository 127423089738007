.custom-modal-open .custom-list-item-helper {
  z-index: 1149;
}
.custom-modal-open {
  overflow: hidden;
}
.custom-modal-wrapper {
  position: fixed;
  overflow: auto;
  z-index: 1050;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.custom-modal {
  display: none;
  overflow: visible;
  outline: 0;
  margin: 0 auto;
  top: 30%;
  position: relative;
  width: auto;
  z-index: 1050;
}
.custom-modal-lg {
  width: 968px;
}
.custom-modal-md {
  width: 800px;
}
.custom-modal-sm {
  width: 600px;
}
.custom-modal-xs {
  width: 400px;
}
.custom-modal-full {
  width: calc(100% - 120px);
}
.custom-modal-full .custom-modal-dialog {
  margin-left: 60px;
  margin-right: 60px;
}
.custom-modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
}
.custom-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #272c36;
}
.custom-modal-backdrop.fade {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}
.custom-modal-backdrop.in {
  opacity: 0.3;
}
.custom-modal-header {
  padding: 15px;
}
.custom-modal-header::before,
.custom-modal-header::after {
  content: ' ';
  display: table;
}
.custom-modal-header::after {
  clear: both;
}
.custom-modal-header::before,
.custom-modal-header::after {
  content: ' ';
  display: table;
}
.custom-modal-header::after {
  clear: both;
}
.custom-modal-header .custom-modal-title {
  font-size: 16px;
  line-height: 1.25;
  display: block;
  color: #575757;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.custom-modal-header .custom-modal-header-close {
  outline: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 12px;
  line-height: 1.66666667;
  color: #575757;
  width: 20px;
  padding: 0 4px;
  background: none;
  border-style: none;
}
.custom-modal-header .custom-modal-header-close::before {
  font-family: 'rsuite-icon-font';
  font-style: normal;
  line-height: 1;
  content: '\f00d';
}
.custom-modal-header .custom-modal-header-close [aria-hidden] {
  display: none;
}
.custom-modal-header .title {
  margin: 0;
  color: #3498ff;
  font-size: 16px;
  line-height: 1.375;
}
.custom-modal-body {
  position: relative;
  margin-top: 30px;
  padding-bottom: 30px;
}
.custom-modal-footer {
  text-align: right;
  border-top: 1px solid #cecece;
  padding: 10px 15px;
  background-color: #f9f9f9;
  border-radius: 0px 0px 6px 6px;
}
.custom-modal-footer::before,
.custom-modal-footer::after {
  content: ' ';
  display: table;
}
.custom-modal-footer::after {
  clear: both;
}
.custom-modal-footer::before,
.custom-modal-footer::after {
  content: ' ';
  display: table;
}
.custom-modal-footer::after {
  clear: both;
}
.custom-modal-footer .rs-btn + .rs-btn {
  margin-left: 10px;
  margin-bottom: 0;
}
.custom-modal-footer .rs-btn-group .rs-btn + .rs-btn {
  margin-left: -1px;
}
.custom-modal-footer .rs-btn-block + .rs-btn-block {
  margin-left: 0;
}
.custom-modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}