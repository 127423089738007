$red: rgb(201, 53, 50);
$text-black: #21252A;
$border-grey: #cecece;
$disable-grey: #efefef;
$success-green: #24A148;
$text-blue: #0E65E5; 

input::placeholder {
  opacity: 0.5
}

.field-label {
  margin-bottom: 7px;
  color: $text-black;

  .error {
    color: $red;
  }

  &__required-label {
    margin-left: 3px;
  }
}

.nav-field-label {
  display: flex;
  align-items: center;

  &__icon {
    margin-left: 5px;
    margin-bottom: -2px;
    cursor: pointer;
  }

  &__text {
    color: $text-blue;
    margin-left: 5px;
  }
}

.text-input {
  $self: &;
  display: flex;
  flex-direction: column;
  &--error {
    border-color: $red; 
  }

  &--disable {
    background-color: $disable-grey; 
  }

  &--click-to-copy {
    background-color: $disable-grey; 
    cursor: pointer;
  }

  &--input-container {
    display: flex;
    border-color: $border-grey;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    align-items: center;
    padding-right: 10px;
  }

  &--input {
    padding: 8px 12px;
    border: none;
    font-size: 14px;
    border-radius: 4px;
    text-overflow: ellipsis;
    outline: none;
    flex-grow: 1;
    background-color: inherit;
  }

  &__error-message {
    color: $red;
    font-size: 14px;
    margin-top: 4px;
    margin-left: 2px;
    height: 10px;

    &--empty {
      margin-top: 4px;
      height: 10px;
    }
  }

  &__success-message {
    color: $success-green;
    font-size: 12px;
    margin-top: -8px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    visibility: visible;

    &--empty {
      margin-top: -8px;
      margin-bottom: 12px;
      font-size: 12px;
      visibility: hidden;
    }
  }

  &__check {
    color: $success-green;
    margin-right: 3px;
  }
}

.info-field {
  display: flex;
  align-items: center;

  &__copy-element {
    display: flex;
    align-items: center;
    color: $success-green;
  }

  &__check {
    margin-right: 3px;
  }

  &__copy {
    cursor: "pointer";
    color: $text-black;
  }

  &__text {
    font-size: 16px;
    margin-top: 0px;
    color: $text-black;
    margin-right: 10px;
  }
}

.click-to-copy-field {
  position: relative;

  &__hover-wrapper {
    margin-right: 40px;
    z-index: 99;
    position: absolute;
    width: 400px;
    top: 25px;
    height: 34px;
  }

  &__hover-value-text {
    z-index: 99;
    position: absolute;
    background-color: $text-black;
    opacity: 0.8;
    font-size: 14px;
    color: #fff;
    display: flex;
    max-width: 300px;
    padding: 5px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    word-break: break-word;
    top: 65px;
  }
}